import "../scss/app.scss";
import Vue from "vue";
const carousels = require("./carousels");

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    childnav: {
      isChildnavA: false,
      isChildnavB: false,
      isChildnavC: false,
    },
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
  },
  methods: {
    toggleChildnav(target) {
      const parent = this.childnav;
      for (let child of Object.keys(parent)) {
        if (parent[child] == !parent[target]) {
          parent[child] = false;
        }
      }
      parent[target] = !parent[target];
    },
  },
});
